import React, { useEffect, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import styled from 'styled-components'
import RightChevron from '../img/right-chevron.svg'
import LeftChevron from '../img/left-chevron.svg'

const Container = styled.div`
  position: relative;
  grid-area: carousel;
  overflow: hidden;
`

const Wrapper = styled.div`
  margin: 24px auto;
  @media only screen and (min-width: 960px) {
    width: ${({width}) => width};
  }
`

const Inner = styled.div`
  transition: transform 0.3s;
  white-space: nowrap;
`

const Left = styled.button`
  position: absolute;
  top: 45%;
  left: 0;
  width: 48px;
  height: 48px;
  padding-top: 4px;
  padding-right: 8px;
  border-radius: 50%;
  background-color: #ffffff7a;
  border: 1px solid #e1e4ea;

  &:hover {
    background-color: #e1e4ea7a;
    cursor: pointer;
  }
`

const Right = styled.button`
  position: absolute;
  top: 45%;
  right: 0;
  width: 48px;
  height: 48px;
  padding-top: 4px;
  padding-left: 8px;
  border-radius: 50%;
  background-color: #ffffff7a;
  border: 1px solid #e1e4ea;

  &:hover {
    background-color: #e1e4ea7a;
    cursor: pointer;
  }
`

const Carousel = ({ children, width, ...props }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [paused, setPaused] = useState(false)

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0
    }

    setActiveIndex(newIndex)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1)
      }
    }, 3000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  })

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
  })

  return (
    <Container>
      <Wrapper
        width={width}
        {...handlers}
        onMouseEnter={() => setPaused(true)}
        onMouseLeave={() => setPaused(false)}
      >
        <Inner style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
          {React.Children.map(children, (child, index) => {
            const { photo, author, comments } = child.props
            return React.cloneElement(child, { photo, author, comments })
          })}
        </Inner>
        <Left
          aria-label="previous"
          onClick={() => {
            updateIndex(activeIndex - 1)
          }}
        >
          <LeftChevron />
        </Left>
        <Right
          aria-label="next"
          onClick={() => {
            updateIndex(activeIndex + 1)
          }}
        >
          <RightChevron />
        </Right>
      </Wrapper>
    </Container>
  )
}

export default Carousel
