import React from 'react'
import styled from 'styled-components'

const PrimButton = styled.button`
  padding: 12px 24px 16px 24px;
  background: linear-gradient(90deg, #fdc03c 0%, #ffd08a 100%);
  border: none;
  font-size: 1.6rem;
  color: black;

  &:hover {
    cursor: pointer;
    background: linear-gradient(90deg, #f2a93c 0%, #ffeacb 100%);
  }
`

const SecButton = styled.button`
  padding: 12px 24px;
  background: linear-gradient(90deg, #5c6263 0%, #0a0d16 100%);
  color: #f2f1eb;
  border: none;

  &:hover {
    cursor: pointer;
    background: linear-gradient(90deg, #989e9e 0%, #2f2f2f 100%);
  }
`

const PrimaryButton = ({ children, ...props }) => {
  return <PrimButton {...props}>{children}</PrimButton>
}

export default PrimaryButton

export const SecondaryButton = ({ children, ...props }) => {
  return <SecButton {...props}>{children}</SecButton>
}
