import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Reviews from '../components/Reviews'
import Blog from '../components/Blog'
import Banner from '../components/Banner'
import Contact from '../components/Contact'

// eslint-disable-next-line
export const IndexPageTemplate = ({
  heading,
  subheading,
  mainpitch,
  socialmedia,
  location,
}) => {
  return (
    <div>
      <Banner
        heading={heading}
        subheading={subheading}
        mainpitch={mainpitch}
        location={location}
      />
      <Blog location={location}/>
      <Reviews />
      <Contact socialmedia={socialmedia} />
    </div>
  )
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  agent: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  location: PropTypes.object,
}

const IndexPage = ({ location, data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout location={location}>
      <IndexPageTemplate
        location={location}
        image={frontmatter.image}
        agent={frontmatter.agent}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        socialmedia={frontmatter.socialmedia}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate($id: String!) {
    markdownRemark(
      id: { eq: $id }
      frontmatter: { templateKey: { eq: "index-page" } }
    ) {
      frontmatter {
        title
        heading
        subheading
        mainpitch {
          title
          description
        }
        socialmedia {
          name
          link
          qr {
            childImageSharp {
              gatsbyImageData(width: 100, quality: 100, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`
