import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";
import { SecondaryButton } from "./Button";
import { Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { useTranslation } from "react-i18next";
import { Motionize } from "./Motion";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const MaxWidthContainer = styled.div`
  display: grid;
  background-color: #f5f5f5;
  opacity: 0.8;
  background-image: repeating-radial-gradient(
      circle at 0 0,
      transparent 0,
      #f5f5f5 15px
    ),
    repeating-linear-gradient(#ebebeb55, #ebebeb);
  margin: 0 auto;
  padding-bottom: 48px;
  grid-template-columns:
    minmax(24px, auto) minmax(auto, 960px)
    minmax(24px, auto);
  grid-template-areas:
    ". heading ."
    ". description ."
    ". socialmedia ."
    ". form .";

  @media only screen and (min-width: 960px) {
    grid-template-rows: auto auto 1fr;
    grid-template-columns:
      minmax(24px, auto) minmax(auto, 600px) minmax(auto, 700px)
      minmax(24px, auto);
    grid-template-areas:
      ". heading heading ."
      ". description form ."
      ". socialmedia form .";
  }
`;

const Heading = styled(Motionize("h1"))`
  grid-area: heading;
  margin: 48px auto;
`;

const Description = styled(Motionize("p"))`
  grid-area: description;
`;

const SocialMedia = styled(Motionize("div"))`
  grid-area: socialmedia;
  margin-top: 24px;
  display: flex;
`;

const Card = styled.div`
  margin-right: 24px;
  display: flex;
  flex-direction: column;
`;

const Name = styled(Link)`
  margin-bottom: 12px;
  text-decoration: none;
  color: black;
`;

const Form = styled(Motionize("form"))`
  grid-area: form;
  display: grid;
  grid-gap: 16px;
  grid-template-rows: auto auto 150px auto;
  grid-template-areas:
    "name"
    "email"
    "enquiry"
    "button"
    "recaptcha";
  margin-top: 24px;

  @media only screen and (min-width: 960px) {
    grid-template-rows: auto 150px auto auto;
    grid-template-columns: 1fr 2fr;
    margin-top: 0;
    grid-template-areas:
    "name email"
    "enquiry enquiry"
    "button recaptcha";
  }
`;

const RecaptchaBox = styled(ReCAPTCHA)`
  grid-area: recaptcha;
`;

const FormElement = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 8px;
`;

const Input = styled.input`
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  &:focus {
    outline: none;
  }
`;

const TextArea = styled.textarea`
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  height: 150px;
  &:focus {
    outline: none;
  }
`;

const Contact = ({ socialmedia }) => {
  const { t } = useTranslation();
  const [state, setState] = useState({})
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const recaptchaKey = process.env.GATSBY_RECAPTCHA_KEY;
  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const recaptchaValue = form.elements["g-recaptcha-response"].value;
    if (!recaptchaValue) {
      // Handle reCAPTCHA validation error (recaptchaValue is empty)
      return;
    }
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'g-recaptcha': recaptchaKey,
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => onOpenModal())
      // .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  
  return (
    <MaxWidthContainer id="contact">
      <Heading>{t("free_consultation")}</Heading>
      <Modal open={open} onClose={onCloseModal} center>
        <h2>{t("thankyou")}</h2>
        <p>
         {t("received")}
        </p>
      </Modal>
      <Description>
        {t("feel_free")} <br />
        info@bennyauproperties.com.au <br />
        <br />
        {t("or_via")}
      </Description>
      <SocialMedia>
        {socialmedia &&
          socialmedia.map(({ name, qr, link }, index) => (
            <Card key={index}>
              <Name to={link}>{name}</Name>
              <GatsbyImage
                image={getImage(qr) || qr}
                alt={name || "social-media"}
              />
            </Card>
          ))}
      </SocialMedia>
      <Form
        name="enquiry"
        delay="0.1"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <RecaptchaBox sitekey={recaptchaKey} />
        <input type="hidden" name="form-name" value="enquiry" />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <div data-netlify-recaptcha="true"></div>
        <FormElement style={{ gridArea: "name" }}>
          <Label for="name" htmlFor="name">
            {t("name")}
          </Label>
          <Input
            type="text" name="name" onChange={handleChange}
          />
        </FormElement>
        <FormElement style={{ gridArea: "email" }}>
          <Label for="email" htmlFor="email">
            {t("email")}
          </Label>
          <Input
            type="email" name="email" onChange={handleChange}
          />
        </FormElement>
        <FormElement style={{ gridArea: "enquiry" }}>
          <Label for="message" htmlFor="message">
            {t("enquiry")}
          </Label>
          <TextArea
            onChange={handleChange}
            name="message"
          />
        </FormElement>
        <SecondaryButton
          type="submit"
          style={{ gridArea: "button", marginTop: 8, marginBottom: 8 }}
        >
          {t("send")}
        </SecondaryButton>
      </Form>
    </MaxWidthContainer>
  );
};

export default Contact;
