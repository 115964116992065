import React from "react";
import styled from "styled-components";
import Avatar from "react-avatar";
import { Rating } from "./Rating";

const Item = styled.div`
  white-space: normal;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  height: auto;
  padding: 18px 12px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 12px;
  display: inline-grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 48px 1fr;
  grid-template-areas:
    "photo author"
    "rating rating"
    "comments comments";
`;

const Time = styled.div`
  font-size: 0.875rem;
  color: #70757a;
  padding-left: 6px;
`;
const RatingAndTime = styled.div`
  grid-area: rating;
  margin: 8px 0 0 0;
  display: flex;
  align-items: center;
`;

const Photo = styled(Avatar)`
  grid-area: photo;
  width: 40px;
  border-radius: 100%;
`;

const More = styled("a")`
  grid-area: more;
  font-size: 1rem;
  line-height: 2rem;
  margin-bottom: 2em;
  margin-left: 0.5rem;
  display: inline-block;
  color: #365b6d;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #637f8d;
  }
`;

// const PhotoFromUrl = styled.img`
//   grid-area: photo;
//   width: 40px;
//   border-radius: 100%;
// `

// const Time = styled.div`
//   grid-area: time;
//   color: #6d6d6d;
// `

const Author = styled.cite`
  grid-area: author;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

const Comments = styled.div`
  grid-area: comments;
  p {
    font-size: 1rem;
    line-height: 1.2rem;
  }
`;

export const CarouselItem = ({ author, comments, rating, time }) => {
  return (
    <Item>
      <Photo name={author.split(" ").slice(0, 2).join(" ")} size={40} />
      {/* <PhotoFromUrl src={photoUrl} alt={author} /> */}
      {/* <Time>{time}</Time> */}
      <Author>{author}</Author>
      <RatingAndTime>
        <Rating numberOfStars={rating} />
        <Time>{time}</Time>
      </RatingAndTime>
      <Comments>
        {comments.split("\\n").map((c, key) => (
          <p key={key} title={c}>
            {c.length > 460 ? `${c.slice(0, 460)}...` : c}
            {c.length > 460 && <More href="https://g.co/kgs/vJsrsLt">More</More>}
          </p>
        ))}
      </Comments>
    </Item>
  );
};
