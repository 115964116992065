import React from 'react'
import FullWidthImage from './FullWidthImage'
import Navbar from './Navbar'
import Button from './Button'
import styled from 'styled-components'
import { Link, withPrefix } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { pathDict } from '../constants'
import { Motionize } from './Motion'

const MaxWidthContainer = styled.div`
  display: grid;
  margin: 0 auto;
  min-height: 720px;
  grid-template-rows: 24px 8em 3em 1fr 1.2fr;
  grid-template-columns: minmax(24px, auto) minmax(auto, 1300px) minmax(
      24px,
      auto
    );
  grid-template-areas:
    '. . .'
    '. nav .'
    '. . .'
    '. headings .'
    '. mainpitch .';

  @media only screen and (min-width: 1280px) {
    min-height: 960px;
    grid-template-rows: 24px 8em 4em 1fr 1fr;
    grid-template-columns: minmax(24px, auto) minmax(auto, 1300px) minmax(
        24px,
        auto
      );
    grid-template-areas:
      ' . .  .'
      '. nav .'
      '. . .'
      '. headings .'
      '. mainpitch .';
  }
`

const BookACall = styled(Motionize(Link))`
  margin-top: 24px;
  align-self: flex-start;
  text-decoration: none;
`

const HeadingsContainer = styled.div`
  grid-area: headings;
  position: relative;
  display: flex;
  flex-direction: column;
`

const MainpitchContainer = styled.div`
  grid-area: mainpitch;
  z-index: 1;
  margin-top: 24px;
`

const Title = styled(Motionize('h1'))`
  color: #f2f1eb;
`

const Subheading = styled(Motionize('h2'))`
  color: #f2f1eb;
  margin-top: 0.25em;
`

const MainpitchTitle = styled(Motionize('h1'))``

const MainpitchDescription = styled(Motionize('h2'))`
  margin-top: 0.25em;
`

const LearnMore = styled(Motionize(Link))`
  font-size: 1.6rem;
  line-height: 4rem;
  margin-bottom: 2em;
  display: block;
  color: #365b6d;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #637f8d;
  }
`

// const Agent = styled(GatsbyImage)`
//   grid-area: agent;
//   z-index: 2;
//   @media only screen and (max-width: 960px) {
//     display: none;
//   }
// `

const Banner = ({ location, heading, subheading, agent, mainpitch }) => {
  const { t } = useTranslation()
  const isCN = location && location.pathname.includes(withPrefix('/cn'))
  const path = isCN ? pathDict.cn : pathDict.en
  return (
    <MaxWidthContainer>
      <Navbar location={location} />
      <FullWidthImage withCurve gridArea="1/1/5/4" />
      {(heading || subheading) && (
        <HeadingsContainer>
          {/* Any content here will be centered in the component */}
          {heading && <Title>{heading}</Title>}
          {subheading && <Subheading delay="0.1">{subheading}</Subheading>}
          <BookACall delay="0.2" to="/#contact">
            <Button>{t('book_a_call')}</Button>
          </BookACall>
        </HeadingsContainer>
      )}
      {/* <Agent
        image={agent}
        alt="agent photo"
        formats={['auto', 'webp', 'avif', 'png']}
      /> */}
      <MainpitchContainer>
        <MainpitchTitle>{mainpitch.title}</MainpitchTitle>
        <MainpitchDescription>{mainpitch.description}</MainpitchDescription>
        <LearnMore to={path.services}>{t('learn_more')} &#8250;</LearnMore>
      </MainpitchContainer>
    </MaxWidthContainer>
  )
}

export default Banner
