import React from 'react'
import styled from 'styled-components'
import StarIcon from '../img/star.svg'

const Star = styled(StarIcon)`
  margin-right: 2px;
`;


export const Rating = ({ numberOfStars }) => {
 return Array.from({ length: numberOfStars }, (_, index) => <Star key={index} />);
}
